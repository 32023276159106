<template>
  <div class="page pcHourlyWorks pa-3">
    <v-card class="d-flex justify-space-between pl-3">
      <v-col cols="2" class="pa-0">
        <v-text-field
          class="mobHourlyWorks_header_month ma-0 pa-0 pt-4"
          color="#e0a677"
          v-model="filterHourlyWorksByMonthText"
          @click="selectMonth()"
          placeholder="Месяц"
        />
      </v-col>
      <v-col cols="2" class="px-2 py-4 d-flex justify-end">
        <v-btn icon @click="createTask()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-card>
    
    <div class="mobHourlyWorks_cont mt-3">
      <v-card>
        <div v-if="!hourlyWorks || hourlyWorks.length === 0" class="pa-3 d-flex justify-center">
          Нет сделанной работы
        </div>
        <v-simple-table
          v-if="hourlyWorks.length > 0"
          class="mobHourlyWorks_cont_table"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Дата</th>
                <th class="text-left">Часы</th>
                <th class="text-left">Что сделал</th>
                <th class="text-left">Итого</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="task in hourlyWorks"
                :key="task.id"
                class="tableItemList"
                @click="editTask(task)"
                :set="ifHPWM = $ifHourPayWorkMasters(task, user)"
              >
                <td style="min-width: 85px;">{{ $formatDate(task.date.toDate()) }}</td>
                <td style="min-width: 90px;">
                  <span v-if="task.hourPayWorkMasters && user.role != 'prodDir'">
                    {{ ifHPWM.allHour }} {{ $numWord(ifHPWM.allHour, ['час', 'часа', 'часов']) }}
                  </span>
                  <span v-else>
                    {{ task.numberOfHours }} {{ $numWord(task.numberOfHours, ['час', 'часа', 'часов']) }}
                  </span>
                </td>
                <td style="min-width: 120px;">{{ $sliceStr($getStrTasks(task.tasks), 80) }}</td>
                <td>
                  <span v-if="task.hourPayWorkMasters && user.role != 'prodDir'">
                    {{ ifHPWM.cost }}р.
                  </span>
                  <span v-else-if="task.numberOfHours && task.rate">
                    {{ Number(task.numberOfHours) * task.rate }}р.
                  </span>
                  <span v-else>Ничего</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import SelectMonth from '@/components/Modals/SelectMonth'
import ModalTask from './Components/ModalTask/Index'

export default {
  data: () => ({}),
  computed: {
    hourlyWorks() {
      return this.$store.getters['hourlyWorks/getTasks']
    },
    filterHourlyWorksByMonthText() {
      let month = this.filterHourlyWorksByMonth
      return String(new Date(month).toLocaleString('ru', {month: 'long', year: 'numeric'}))
    },
    filterHourlyWorksByMonth: {
      get() {
        return this.$store.getters['hourlyWorks/getFilterHourlyWorksByMonth']
      },
      set(val) {
        this.$store.commit('hourlyWorks/setFilterHourlyWorksByMonth', val)
      }
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    getHourPayWorkMasters() {
      return this.$store.getters['settings/getHourPayWorkMasters']
    }
  },
  methods: {
    async editTask(task) {
      let editTask = await this.$showModal({
        component: ModalTask,
        isPersistent: true,
        props: {
          task: task
        }
      })
      if (editTask) {
        editTask.date = new Date(editTask.date)
        this.$store.dispatch('hourlyWorks/updateTask', { data: editTask, id: task.id })
      }
    },
    async createTask() {
      let newTask = await this.$showModal({
        component: ModalTask,
        isPersistent: true,
        props: {}
      })
      if (newTask) {
        newTask.hourPayWorkMasters = this.getHourPayWorkMasters
        newTask.date = new Date(newTask.date)
        newTask.user = this.user.uid
        if (this.user) {
          newTask.rate = Number(this.user.rate || 0)
          newTask.rateOnManufactory = Number(this.user.rateOnManufactory || 0)
          newTask.rateOnMontage = Number(this.user.rateOnMontage || 0)
          newTask.rateOverwork = Number(this.user.rateOverwork || 0)
        } else {
          newTask.rate = 0
        }

        this.$store.dispatch('hourlyWorks/createTask', newTask)
      }
    },
    async selectMonth() {
      let modalMonth = await this.$showModal({
        component: SelectMonth,
        isPersistent: true,
        props: {
          title: 'Выберите месяц',
          selectMonth: this.filterHourlyWorksByMonth
        }
      })
      if (modalMonth) {
        this.filterHourlyWorksByMonth = modalMonth
      }
    },
  }
}
</script>

<style lang="scss">
.pcHourlyWorks {
  height: 100%;

  &_header {
    &_month {
      .v-text-field__details {
        display: none;
      }
    }
  }

  &_cont {
    height: 100%;
  }
}
</style>